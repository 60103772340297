import { getField, updateField } from 'vuex-map-fields'
import { prepareUrl, uri } from '../api/uri'

export const initState = {}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  async blockTalk ({ commit }, id) {
    const url = `${prepareUrl(uri.talks.blockTalk, { id })}`
    await this.$axios.$post(url)
      .then((resp) => {
        // console.log('Enable Feedback', url, resp)
      }).catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        }, { root: true })
      })
  },
  async unlockTalk ({ commit }, id) {
    const url = `${prepareUrl(uri.talks.unlockTalk, { id })}`
    await this.$axios.$post(url)
      .then((resp) => {
        // console.log('Enable Feedback', url, resp)
      }).catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        }, { root: true })
      })
  },
  async deleteFeedback ({ commit, dispatch }, id) {
    commit('flushErrors', null, { root: true })
    const url = `${prepareUrl(uri.talks.deleteFeedback, { id })}`
    // send message
    await this.$axios.$delete(url)
      .then((resp) => {
        // console.log('Delete Feedback', url, resp)
      })
      .catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        }, { root: true })
      })
  },
  async enableFeedback ({ commit, dispatch }, id) {
    commit('flushErrors', null, { root: true })
    const url = `${prepareUrl(uri.talks.enableFeedback, { id })}`
    // send message
    await this.$axios.$post(url)
      .then((resp) => {
        // console.log('Enable Feedback', url, resp)
      })
      .catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        }, { root: true })
      })
  },
  async refundTalk ({ commit, dispatch }, id) {
    commit('flushErrors', null, { root: true })
    const url = `${prepareUrl(uri.talks.refundTalk, { id })}`
    // send message
    await this.$axios.$post(url)
      .then((resp) => {
        // console.log('refundTalk', url, resp)
      })
      .catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        }, { root: true })
      })
  }
}
export const mutations = {
  updateField
}
export const getters = {
  getField
}
